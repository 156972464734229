import React, { useState, useEffect } from "react";
import { useParams, useNavigate, json } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Items({ currentItems }) {
	return (
		<>
			{currentItems &&
				currentItems.map((user, idx) => (
					<tr>
						<th scope="row">
							<img
								alt="Person"
								src={user.profile_image}
								width="50vw"
								height="50vw"
							></img>
						</th>
						<td>
							<a href={user.profile_url}>{user.screen_name}</a>
						</td>
						<td>{user.followers.toLocaleString("en-US")}</td>
						<td>{user.tweets.toLocaleString("en-US")}</td>
						<td>{user.description}</td>
					</tr>
				))}
		</>
	);
}

export default function ViewLeads() {
	const [leads, setLeads] = useState([]);
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [count, setCount] = useState(0);
	const [pages, setPages] = useState(0);
	const [name, setName] = useState("Leads");

	const navigate = useNavigate();

	const handlePageClick = (event) => {
		// const newOffset = (event.selected * itemsPerPage) % items.length;
		let newPage = event.selected + 1;
		fetch(`${process.env.REACT_APP_SERVER_URL}/leads/${id}/${newPage}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setLeads(json.leads);
			window.scrollTo({ top: 0, left: 0, behavior: "instant" });
		});
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/leads/${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setLeads(json.leads);
			setCount(json.count);
			setPages(json.pages);
			setName(json.name);
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<div className="main-wrap" style={{ width: "100vw" }}>
				<div style={{ textAlign: "center", marginTop: "2vh" }}>
					<h2
						style={{
							fontWeight: "600",
							fontSize: "3em",
							color: "rgb(0, 0, 0)",
							opacity: 0.7,
						}}
					>
						{name} ({count})
					</h2>
					<p
						style={{
							fontSize: "1.5em",
							marginLeft: "0.2rem",
							fontWeight: 100,
							marginTop: "1rem",
							width: "100%",
							color: "rgb(0, 0, 0)",
							opacity: 0.7,
						}}
					>
						Showing {leads.length} leads
					</p>
				</div>

				<div id="hero" style={{ marginTop: "3vh", width: "50vw" }}>
					<div>
						<table className="table table-striped table-bordered">
							<thead>
								<tr>
									<th scope="col">Photo</th>
									<th scope="col">Name</th>
									<th scope="col">Followers</th>
									<th scope="col">Tweets</th>
									<th scope="col">Description</th>
								</tr>
							</thead>
							<colgroup>
								<col span="Photo" style={{ width: "4%", maxWidth: "4%" }}></col>
								<col span="Name" style={{ width: "10%" }}></col>
								<col span="Followers" style={{ width: "10%" }}></col>
								<col span="Tweets" style={{ width: "10%" }}></col>
								<col span="Description" style={{ width: "50%" }}></col>
							</colgroup>
							<tbody>
								<>
									<Items currentItems={leads} />
								</>
							</tbody>
						</table>
						<ReactPaginate
							breakLabel="..."
							nextLabel="next"
							onPageChange={handlePageClick}
							pageRangeDisplayed={2}
							pageCount={pages}
							previousLabel="prev"
							renderOnZeroPageCount={null}
							className="pagination-container"
							pageClassName="pagination-page"
						/>
					</div>
				</div>
			</div>
		);
	}
}
