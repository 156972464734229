import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function MyLeads() {
	const [leads, setLeads] = useState([]);
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();
	const ref = useRef(null);

	function navigateFindLeads() {
		navigate("/leads/find");
	}

	function navigateCreateLeads() {
		navigate("/leads/new");
	}

	function closeAll() {
		const element = ref.current;
		let elChildren = element.children;
		for (let child of elChildren) {
			let divHide = child.children[0].lastChild.lastChild;
			if (!divHide.classList.contains("hidden")) {
				divHide.classList.add("hidden");
			}
		}
	}

	function openOptions(e) {
		e.preventDefault();
		if (e.target.localName === "button") return;
		let isHidden = e.target.children[1].classList.contains("hidden");
		if (isHidden) {
			closeAll();
			e.target.children[1].classList.remove("hidden");
		} else {
			e.target.children[1].classList.add("hidden");
		}
	}

	function deleteList(e) {
		let _id = e.target.dataset.listId;
		fetch(`${process.env.REACT_APP_SERVER_URL}/leads`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				_id,
			}),
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			window.location.reload(false);
		});
	}

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/leads`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setLeads(json.leadsLists);
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<>
				<div className="main-wrap">
					<div className="main-wrap-page-campaigns">
						<div className="about-campaigns">
							<div className="header-about">
								<h2
									id="h2-header-about"
									style={{
										fontWeight: "600",
										fontSize: "40px",
										color: "#565656",
									}}
								>
									Leads
								</h2>
								<div className="buttons">
									<button onClick={navigateFindLeads} className="button-27">
										<span style={{ fontSize: "30px", marginBottom: "0.3rem" }}>
											+
										</span>
										<p>FIND LEADS</p>
									</button>

									<button
										onClick={navigateCreateLeads}
										className="button-28"
										style={{
											fontWeight: 700,
											fontSize: "15px",
										}}
									>
										<span style={{ fontSize: "30px", marginBottom: "0.3rem" }}>
											+
										</span>
										<p>NEW LIST</p>
									</button>
								</div>
							</div>
						</div>

						{leads.length === 0 && (
							<p id="no-lead-lists">You have no lead lists. Please create one.</p>
						)}
						{leads.length > 0 && (
							<>
								<div className="secondary-hero" ref={ref}>
									{leads.length < 1 ? (
										<p id="no-lead-lists">
											You have no lead lists. Please create one.
										</p>
									) : (
										leads.map((list) => {
											return (
												<a
													href={"/leads/" + list._id}
													style={{ textDecoration: "none" }}
													key={list._id}
												>
													<div
														style={{
															gridTemplateColumns: "repeat(3, 1fr)",
														}}
														className="list-item"
													>
														<th scope="row">
															<p>{list.name}</p>
															<p className="time-ago">
																{list.timeFormatted}
															</p>
														</th>

														<div className="list-icons">
															<td
																style={{
																	marginRight: "1rem",
																	display: "flex",
																}}
															>
																<svg
																	viewBox="0 0 24 24"
																	fill="#bdbdbd"
																	xmlns="http://www.w3.org/2000/svg"
																	className="campaign-info-icons"
																	style={{
																		marginRight: "0.3rem",
																	}}
																>
																	<path
																		d="M17.9998 15.8369C19.4557 16.5683 20.704 17.742 21.6151 19.2096C21.7955 19.5003 21.8857 19.6456 21.9169 19.8468C21.9803 20.2558 21.7006 20.7585 21.3198 20.9204C21.1323 21 20.9215 21 20.4998 21M15.9998 11.5322C17.4816 10.7959 18.4998 9.26686 18.4998 7.5C18.4998 5.73314 17.4816 4.20411 15.9998 3.46776M13.9998 7.5C13.9998 9.98528 11.9851 12 9.49984 12C7.01455 12 4.99984 9.98528 4.99984 7.5C4.99984 5.01472 7.01455 3 9.49984 3C11.9851 3 13.9998 5.01472 13.9998 7.5ZM2.55907 18.9383C4.15337 16.5446 6.66921 15 9.49984 15C12.3305 15 14.8463 16.5446 16.4406 18.9383C16.7899 19.4628 16.9645 19.725 16.9444 20.0599C16.9287 20.3207 16.7578 20.64 16.5494 20.7976C16.2818 21 15.9137 21 15.1775 21H3.82219C3.08601 21 2.71791 21 2.45028 20.7976C2.24189 20.64 2.07092 20.3207 2.05527 20.0599C2.03517 19.725 2.2098 19.4628 2.55907 18.9383Z"
																		stroke="#bdbdbd"
																		strokeWidth="2"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																{list.leads}
															</td>
														</div>

														<div
															className="edit-campaign"
															style={{ paddingLeft: "2rem" }}
															onClick={openOptions}
														>
															<svg
																width="1.5rem"
																height="1.5rem"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																style={{
																	marginTop: "0.7rem",
																	pointerEvents: "none",
																}}
															>
																<path
																	d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
																	stroke="#000000"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
																	stroke="#000000"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
																	stroke="#000000"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>

															<div className="popup hidden">
																<button
																	type="button"
																	className="btn btn-outline-danger popup-btn"
																	onClick={deleteList}
																	data-list-id={list._id}
																>
																	Delete
																</button>
															</div>
														</div>

														<div
															className="edit-campaign-mobile"
															style={{ paddingLeft: "2rem" }}
															onClick={openOptions}
														>
															<svg
																width="1.5rem"
																height="1.5rem"
																viewBox="0 0 24 24"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																style={{
																	marginTop: "0.7rem",
																	pointerEvents: "none",
																}}
															>
																<path
																	d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
																	stroke="#000000"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
																	stroke="#000000"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
																	stroke="#000000"
																	strokeWidth="2"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>

															<div className="popup hidden">
																<button
																	type="button"
																	className="btn btn-outline-danger popup-btn"
																	onClick={deleteList}
																	data-list-id={list._id}
																>
																	Delete
																</button>
															</div>
														</div>
													</div>
												</a>
											);
										})
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</>
		);
	}
}
