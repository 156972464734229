import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function MyCampaigns() {
	const [campaigns, setCampaigns] = useState([]);
	const [loading, setLoading] = useState(true);
	const ref = useRef(null);

	const navigate = useNavigate();

	const navigateToCampaign = () => {
		navigate("/campaign/new");
	};

	function closeAll() {
		const element = ref.current;
		let elChildren = element.children;
		for (let child of elChildren) {
			let divHide = child.firstChild.children[3].children[1];
			if (!divHide.classList.contains("hidden")) {
				divHide.classList.add("hidden");
			}
		}
	}

	function openOptions(e) {
		e.preventDefault();
		if (e.target.localName === "button") return;
		console.log(e);
		let isHidden = e.target.children[1].classList.contains("hidden");
		if (isHidden) {
			closeAll();
			e.target.children[1].classList.remove("hidden");
		} else {
			e.target.children[1].classList.add("hidden");
		}
	}

	function deleteCampaign(e) {
		let _id = e.target.dataset.campaignId;
		fetch(`${process.env.REACT_APP_SERVER_URL}/campaign`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				_id,
			}),
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			window.location.reload(false);
		});
	}

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/campaign`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setCampaigns(json.campaigns);
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<div className="main-wrap">
				<div className="main-wrap-page-campaigns">
					<div className="about-campaigns">
						<div className="header-about">
							<h2 className="h2-campaigns">Campaigns</h2>

							<button
								onClick={navigateToCampaign}
								className="button-28"
								style={{ fontWeight: "700", fontSize: "15px" }}
							>
								<span style={{ fontSize: "30px", marginBottom: "0.3rem" }}>+</span>
								<p>ADD NEW</p>
							</button>
						</div>
						{campaigns.length === 0 && (
							<p id="no-lead-lists" style={{ marginTop: "1rem" }}>
								You have no campaigns. Please create one.
							</p>
						)}
						<div ref={ref}>
							{campaigns.map((campaign) => {
								return (
									<a
										href={"/campaign/" + campaign._id}
										style={{ textDecoration: "none" }}
										key={campaign._id}
									>
										<div className="list-item" key={campaign._id}>
											<div className="camp-name">
												<th scope="row">
													<p
														className="campaign-name"
														style={{ marginRight: "1rem" }}
													>
														{campaign.name}
													</p>
													<p className="time-ago">
														{campaign.timeFormatted}
													</p>
												</th>
											</div>

											<div className="camp-badge">
												<td style={{ margin: "auto" }}>
													{Object.hasOwn(campaign, "active") &&
													campaign.active === true ? (
														<span
															className="pill"
															style={{ backgroundColor: "#418f32" }}
														>
															Active
														</span>
													) : (
														<span
															className="pill"
															style={{ backgroundColor: "#dc3545" }}
														>
															Paused
														</span>
													)}
												</td>
												<div
													className="edit-campaign-mobile"
													style={{ paddingLeft: "2rem" }}
													onClick={openOptions}
												>
													<svg
														width="1.5rem"
														height="1.5rem"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
														style={{
															marginTop: "0.7rem",
															pointerEvents: "none",
														}}
													>
														<path
															d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
															stroke="#000000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
															stroke="#000000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
															stroke="#000000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
													<div className="popup hidden">
														<button
															type="button"
															className="btn btn-outline-danger popup-btn"
															onClick={deleteCampaign}
															data-campaign-id={campaign._id}
														>
															Delete
														</button>
													</div>
												</div>
											</div>

											<div className="list-icons">
												<td
													style={{ marginRight: "1rem", display: "flex" }}
												>
													<svg
														style={{ marginRight: "0.3rem" }}
														className="campaign-info-icons"
														fill="#bdbdbd"
														height="1.5rem"
														width="1.5rem"
														version="1.1"
														id="Capa_1"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 334.5 334.5"
													>
														<path
															d="M332.797,13.699c-1.489-1.306-3.608-1.609-5.404-0.776L2.893,163.695c-1.747,0.812-2.872,2.555-2.893,4.481
												s1.067,3.693,2.797,4.542l91.833,45.068c1.684,0.827,3.692,0.64,5.196-0.484l89.287-66.734l-70.094,72.1
												c-1,1.029-1.51,2.438-1.4,3.868l6.979,90.889c0.155,2.014,1.505,3.736,3.424,4.367c0.513,0.168,1.04,0.25,1.561,0.25
												c1.429,0,2.819-0.613,3.786-1.733l48.742-56.482l60.255,28.79c1.308,0.625,2.822,0.651,4.151,0.073
												c1.329-0.579,2.341-1.705,2.775-3.087L334.27,18.956C334.864,17.066,334.285,15.005,332.797,13.699z"
														/>
													</svg>

													{campaign.total_sent || 0}
												</td>

												<td
													style={{ marginRight: "1rem", display: "flex" }}
												>
													<svg
														style={{ marginRight: "0.3rem" }}
														className="campaign-info-icons"
														height="1.5rem"
														fill="#bdbdbd"
														width="1.5rem"
														version="1.1"
														id="_x32_"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
													>
														<g>
															<path
																className="st0"
																d="M292.497,168.968c-21.134,0-40.287,0-57.542,0V65.394L0,255.995l234.955,190.61V334.395
												c7.132,0,14.331,0,21.578,0c95.305,0,227.772-2.396,237.359,100.701C541.847,322.408,501.086,168.968,292.497,168.968z"
															/>
														</g>
													</svg>
													{campaign.replied_leads.length || 0}
												</td>
											</div>
											<div
												className="edit-campaign"
												style={{ paddingLeft: "2rem" }}
												onClick={openOptions}
											>
												<svg
													width="1.5rem"
													height="1.5rem"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													style={{
														marginTop: "0.7rem",
														pointerEvents: "none",
													}}
												>
													<path
														d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
														stroke="#000000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
														stroke="#000000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
														stroke="#000000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
												<div className="popup hidden">
													<button
														type="button"
														className="btn btn-outline-danger popup-btn"
														onClick={deleteCampaign}
														data-campaign-id={campaign._id}
													>
														Delete
													</button>
												</div>
											</div>
										</div>
									</a>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
