import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function MyLeads() {
	const defaultText =
		"Hey {{name}}, love what you're doing! How are you setting appointments right now?";

	const [items, setItems] = useState([]);
	const [name, setName] = useState("");
	const [lead, setLead] = useState("");
	const [text, setText] = useState([defaultText]);

	const navigate = useNavigate();

	const [dmsPerDay, setDmsPerDay] = useState(0);
	const [loading, setLoading] = useState(true);

	function removeMessage(e) {
		if (text.length > 1) {
			let textCopy = text.slice(0, -1);
			setText(textCopy);
		}
	}

	function addMessage(e) {
		if (text.length < 3) {
			setText([...text, ""]);
		}
	}

	function editText(e) {
		let key = Number(e.target.dataset.key);
		const newText = text.map((c, i) => {
			if (key === i) {
				return e.target.innerText;
			} else {
				return c;
			}
		});
		setText(newText);
	}

	let handleSubmit = async (e) => {
		e.preventDefault();
		try {
			let res = await fetch(`${process.env.REACT_APP_SERVER_URL}/campaign`, {
				method: "POST",
				body: JSON.stringify({
					campaign_name: name,
					text: text,
					dont_dm_dmed: "on",
					leads: lead,
					dms_per_day: dmsPerDay,
				}),
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			});
			let json = await res.json();
			if (Object.hasOwn(json, "error")) {
				toast.error(json.error);
				return;
			}
			toast.success("Created campaign");
			let campaignId = json.campaign._id;
			navigate(`/campaign/${campaignId}`);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/leads`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setItems(json);
			setLead(json.leadsLists[0]._id);
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<>
				<div className="main-wrap-form-leads">
					<div className="about-campaigns"></div>

					<div style={{ marginTop: "0rem" }} id="hero-forms">
						<form
							id="create-campaign-form-id-forms"
							className="create-campaign-form"
							onSubmit={handleSubmit}
							method="POST"
						>
							<div className="page-forms">
								<div className="header-about-forms">
									<div style={{ marginBottom: "2rem" }}>
										<h2
											style={{
												fontWeight: "600",
												fontSize: "30px",
												color: "#505050",
												fontWeight: 600,
												fontFamily: "Poppins",
											}}
											className="form-name"
										>
											Now let's create a campaign
										</h2>
										<p
											style={{
												fontSize: "16px",
												color: "#8492a6",
												fontWeight: "100",
												fontFamily: "Poppins",
											}}
										>
											What name would fit the best?
										</p>
									</div>
								</div>

								<div className="form-group">
									<label
										style={{ fontWeight: "100", color: "#161c2d" }}
										htmlFor="exampleInputEmail1"
									>
										Name
									</label>
									<input
										style={{ height: "3.5rem", fontSize: "18.5px" }}
										onChange={(e) => setName(e.target.value)}
										type="text"
										className="form-control"
										id="exampleInputEmail1"
										aria-describedby="emailHelp"
										placeholder="e.g Fitness Coach Copywriting Outreach"
										value={name}
									/>
								</div>

								<div
									className="page"
									style={{
										maxWidth: "100%",
										marginLeft: "0",
										marginRight: 0,
										width: "100%",
									}}
								>
									<div className="input-group ">
										<div
											className="form-group lead-list-label"
											style={{
												margin: "0px",
												maxWidth: "600px",
												width: "100%",
											}}
										>
											<label
												style={{
													margin: "0px",
													fontWeight: "100",
													color: "#161c2d",
												}}
												htmlFor="exampleInputEmail1"
											>
												Lead list
											</label>
											<label
												className=" label-phone-remove"
												style={{
													marginLeft: "23.7rem",
													fontWeight: "100",
													color: "#161c2d",
												}}
												htmlFor="exampleInputEmail1"
											>
												DMs/day
											</label>
										</div>

										{!Object.hasOwn(items, "leadsLists") && (
											<p id="no-campaign">Please create a new lead list.</p>
										)}
										{Object.hasOwn(items, "leadsLists") &&
											items.leadsLists.length > 0 && (
												<select
													type="text"
													className="form-select form-select-sm"
													placeholder="Start"
													onChange={(e) => setLead(e.target.value)}
													id="inputGroupSelect01"
													style={{
														borderTopLeftRadius: "0.375rem",
														borderBottomLeftRadius: "0.375rem",
														height: "3.5rem",
														fontSize: "18.5px",
													}}
												>
													{items.leadsLists.map((item) => {
														return (
															<option key={item._id} value={item._id}>
																{item.name}
															</option>
														);
													})}
												</select>
											)}

										<div style={{ margin: "0px" }} className="form-group"></div>

										<input
											id="inputGroupSelect01"
											type="text"
											className="form-control form-control-phone-remove"
											placeholder="e.g 30"
											value={dmsPerDay}
											style={{
												maxWidth: "7rem",
												height: "3.5rem",
												fontSize: "18.5px",
											}}
											onChange={(e) => setDmsPerDay(e.target.value)}
										></input>
										{/*
								<div className="phone-display">
									<label
										className="label-phone-display"
										htmlFor="exampleInputEmail1"
									>
										DMs/day
									</label>

									<input
										id="inputGroupSelect01"
										type="text"
										className="form-control form-control-phone-display"
										placeholder="e.g 30"
										value={dmsPerDay}
									></input>
								</div>
											*/}
									</div>
								</div>

								<br />
								<section id="textarea">
									<div className="page-forms">
										<div className="form-group">
											<label
												style={{ fontWeight: "100", color: "#161c2d" }}
												htmlFor="twitterDm"
											>
												Message
											</label>
											<div className="twitter-dm">
												<div className="twitter-dms">
													{text.map((message, idx) => {
														return (
															<p
																contentEditable
																onBlur={(e) => {
																	editText(e);
																}}
																suppressContentEditableWarning={
																	true
																}
																id="twitter-dm-text"
																key={idx}
																data-key={idx}
															>
																{message}
															</p>
														);
													})}
												</div>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														gap: "10px",
													}}
												>
													<button
														type="button"
														onClick={addMessage}
														className="twitter-dm-button rounded-border-gradient"
													>
														+
													</button>
													<button
														type="button"
														onClick={removeMessage}
														className="twitter-dm-button"
													>
														-
													</button>
												</div>
											</div>
										</div>
									</div>
								</section>
								<br />
								<br />
								<div className="page-forms">
									<div
										className="buttons-new-leads"
										style={{
											marginTop: "3.5rem",
											display: "flex",
											justifyContent: "space-evenly",
										}}
									>
										<div style={{ width: "50%" }}>
											<a
												onClick={() => navigate(-1)}
												style={{ textDecoration: "none" }}
											>
												<input
													style={{
														backgroundColor: "transparent",
														border: "none",
														color: "#007bff",
														fontFamily: "Poppins",
														fontSize: "17px",
														width: "50%",
													}}
													className="btn btn-primary form-btn"
													value="Cancel"
												></input>
											</a>
										</div>
										<div style={{ width: "50%" }}>
											<a style={{ textDecoration: "none", width: "100%" }}>
												<input
													style={{
														fontFamily: "Poppins",
														fontSize: "17px",
														width: "100%",
													}}
													className="btn btn-primary form-btn"
													type="submit"
													value="Continue"
												></input>
											</a>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</>
		);
	}
}
