import React from "react";

export default function Authenticate() {
	fetch(`${process.env.REACT_APP_SERVER_URL}/authenticate`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		credentials: "include",
	})
		.then(async (response) => {
			const json = await response.json();
			window.location.replace(json.url);
		})
		.then((data) => {})
		.catch((error) => {
			console.error("Error:", error);
		});
}
