import { useNavigate } from "react-router-dom";

import React from "react";

export default function FormHeader() {
	const navigate = useNavigate();

	return (
		<>
			<header>
				<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
				<link
					rel="stylesheet"
					href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
				></link>
			</header>

			<div id="header-nav">
				<div className="hamburger-menu">
					<input id="menu__toggle" type="checkbox" />
					<label className="menu__btn" htmlFor="menu__toggle">
						<span></span>
					</label>

					<ul className="menu__box">
						<li>
							<a className="menu__item" href="/dashboard">
								Dashboard
							</a>
						</li>
						<li style={{ marginTop: "2rem", marginBottom: "2rem" }}>
							<a className="menu__item" href="/campaigns/my">
								My Campaigns
							</a>
						</li>
						<li>
							<a className="menu__item" href="/leads/my">
								My Leads
							</a>
						</li>
					</ul>
				</div>

				<div className="forms-back-button-div">
					<span
						style={{
							backgroundColor: "#f1f4fc",
							borderRadius: "50%",
							padding: "0.5rem",
						}}
					>
						<i>
							{" "}
							<a onClick={() => navigate(-1)}>
								<svg
									style={{ opacity: "0.75" }}
									width="25px"
									height="25px"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M15 18L9 12L15 6"
										stroke="#000000"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</a>
						</i>
					</span>
					<p className="forms-back-button">Back</p>
				</div>
				{/*  	<div className="logged-in-as">
					<p style={{ marginRight: "2rem", fontSize: "1.3rem" }}>@steinhaus28</p>
				</div>*/}
			</div>
		</>
	);
}
