import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function NewLeads() {
	const [leadsLists, setLeadsLists] = useState([]);
	const [lead, setLead] = useState("");

	const [tweetFilter, setTweetFilter] = useState(false);
	const [followerFilter, setFollowerFilter] = useState(false);
	const [bioFilter, setBioFilter] = useState(false);

	const [findArray, setFindArray] = useState([
		{ type: "search", query: "", queryWord: "Search term", queryPlaceholder: "fitness coach" },
	]);

	const navigate = useNavigate();

	function addFind(e) {
		if (findArray.length < 3) {
			setFindArray([
				...findArray,
				{
					type: "search",
					query: "",
					queryWord: "Search term",
					queryPlaceholder: "fitness coach",
				},
			]);
		}
	}

	function removeFind(e) {
		if (findArray.length > 1) {
			setFindArray(findArray.slice(0, -1));
		}
	}

	const [filters, setFilters] = useState({
		tweets: {
			min: 0,
			max: -1,
		},
		followers: {
			min: 0,
			max: -1,
		},
		keywords: "",
	});

	const [loading, setLoading] = useState(true);

	async function findMyFollowers(e) {
		e.preventDefault();
		try {
			const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/leads/find/my/followers`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			});
			let json = await res.json();
			if (Object.hasOwn(json, "error")) {
				toast.error(json.error);
				return;
			}
			toast.success("Finding leads");
			navigate(`/leads/${json._id}`);
		} catch (e) {
			console.log(e);
		}
	}

	let handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/leads/find`, {
				method: "POST",
				body: JSON.stringify({
					queries: findArray,
					leads_id: lead,
					filters: filters,
				}),
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
			});
			let json = await res.json();
			if (Object.hasOwn(json, "error")) {
				toast.error(json.error);
				return;
			}
			toast.success("Finding leads");
			navigate(`/leads/${lead}`);
		} catch (e) {
			console.log(e);
		}
	};

	function changeFindProperty(property, value, idx) {
		let copyFind = [...findArray];
		copyFind[idx][property] = value;
		setFindArray(copyFind);
	}

	function changeFindQuery(e) {
		let query = e.target.value;
		let idx = e.target.dataset.idx;
		let copyFind = [...findArray];
		copyFind[idx].query = query;
		setFindArray(copyFind);
	}

	function changeFindType(e) {
		let type = e.target.value;
		let idx = e.target.dataset.idx;
		switch (type) {
			case "retweet":
			case "like":
				changeFindProperty("queryWord", "Post URL", idx);
				changeFindProperty(
					"queryPlaceholder",
					"https://twitter.com/naval/status/1002103360646823936",
					idx
				);
				break;
			case "followers":
			case "following":
				changeFindProperty("queryWord", "Profile URL", idx);
				changeFindProperty("queryPlaceholder", "https://twitter.com/naval", idx);
				break;
			case "search":
				changeFindProperty("queryWord", "Search term", idx);
				changeFindProperty("queryPlaceholder", "fitness coach", idx);
				break;
			default:
				break;
		}
		let copyFind = [...findArray];
		copyFind[idx].type = type;
		setFindArray(copyFind);
	}

	let updateFilter = async (input) => {
		input = input.target;
		let type = input.attributes["data-type"].value;
		let value = input.value;
		let tempFilters = filters;

		if (["followers", "tweets"].includes(type)) {
			let change = input.attributes["data-change"].value;
			let value = input.value;
			if (value !== "") {
				tempFilters[type][change] = Number(value);
			} else {
				if (change === "max") tempFilters[type][change] = -1;
				if (change === "min") tempFilters[type][change] = 0;
			}
			setFilters(tempFilters);
		} else if (type === "keywords") {
			tempFilters[type] = value;
		}
	};

	let handleFilterClick = async (check) => {
		check = check.target;
		switch (check.value) {
			case "tweets":
				check.checked ? setTweetFilter(true) : setTweetFilter(false);
				break;
			case "followers":
				check.checked ? setFollowerFilter(true) : setFollowerFilter(false);
				break;
			case "keywords":
				check.checked ? setBioFilter(true) : setBioFilter(false);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/leads`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setLeadsLists(json.leadsLists);
			setLead(json.leadsLists[0]._id);
			setLoading(false);
		});
	}, []);

	if (leadsLists.length === 0) {
		navigate("/leads/new");
	}
	if (!loading) {
		return (
			<>
				<div className="main-wrap-form-leads">
					<div id="hero-forms" style={{ marginTop: "0rem" }}>
						<section id="new-lead-list">
							<form
								style={{ textAlign: "left" }}
								id="create-campaign-form-id-forms"
								onSubmit={handleSubmit}
							>
								<div className="page-forms">
									<div className="about-campaigns form-name-leads">
										<div className="header-about-forms">
											<div style={{ marginBottom: "2rem" }}>
												<h2
													style={{
														fontWeight: "600",
														fontSize: "30px",
														color: "#505050",
														fontWeight: 600,
														fontFamily: "Poppins",
													}}
													className="form-name "
												>
													Now let's find some leads
												</h2>
												<p
													style={{
														fontSize: "16px",
														color: "#8492a6",
														fontWeight: "100",
														fontFamily: "Poppins",
													}}
												>
													<span>Fill in the form</span>
													<span
														style={{
															color: "#7E7E7E",
															textDecoration: "underline #7E7E7E 2px",
															marginLeft: "5px",
														}}
													>
														<a
															href="/"
															onClick={(e) => findMyFollowers(e)}
															style={{ textDecoration: "none" }}
														>
															(or get your own followers)
														</a>
													</span>
												</p>
											</div>
										</div>
									</div>
									<div>
										<div style={{ margin: "0px" }} className="form-group">
											<label
												style={{
													fontWeight: "100",
													color: "#161c2d",
													margin: "0px",
												}}
												htmlFor="leadList"
											>
												Lead list
											</label>
										</div>

										<div id="input-div-cont" className="input-group mb-3">
											<select
												style={{
													height: "3.5rem",
													fontSize: "18.5px",
													color: "#5e5e5e",
												}}
												onChange={(e) => setLead(e.target.value)}
												className="form-select form-select-sm"
												id="leadList"
												defaultValue={lead}
											>
												{leadsLists.map((item) => {
													return (
														<option value={item._id} key={item._id}>
															{item.name} ({item.leads} leads)
														</option>
													);
												})}
											</select>
										</div>
									</div>
									<div
										className="input-group mb-3 filter-by"
										style={{
											marginTop: "-1vh",
											marginBottom: "1vh",
											justifyContent: "left",
										}}
									>
										<label
											style={{ fontWeight: "100", color: "#161c2d" }}
											className="find-leads-label "
										>
											Filter leads by:
										</label>
										<div style={{ marginTop: "0.4vh", paddingBottom: "2vh" }}>
											<div className="form-check form-check-inline form-check-mobile ">
												<input
													className="form-check-input"
													type="checkbox"
													value="tweets"
													onChange={handleFilterClick}
												></input>
												<label
													style={{ fontWeight: "100", color: "#161c2d" }}
													className="form-check-label"
													htmlFor="inlineCheckbox1"
												>
													Tweets
												</label>
											</div>
											<div className="form-check form-check-inline form-check-mobile ">
												<input
													className="form-check-input"
													type="checkbox"
													value="followers"
													onChange={handleFilterClick}
												></input>
												<label
													style={{ fontWeight: "100", color: "#161c2d" }}
													className="form-check-label"
													htmlFor="inlineCheckbox2"
												>
													Followers
												</label>
											</div>
											<div className="form-check form-check-inline form-check-mobile">
												<input
													className="form-check-input"
													type="checkbox"
													value="keywords"
													onChange={handleFilterClick}
												></input>
												<label
													style={{ fontWeight: "100", color: "#161c2d" }}
													className="form-check-label"
													htmlFor="inlineCheckbox2"
												>
													Keywords in bio
												</label>
											</div>
										</div>
										<div
											className="input-group mb-3 "
											style={{ marginTop: "-2vh", justifyContent: "left" }}
										>
											{tweetFilter && (
												<div className="row filter-row ">
													<div className="col ">
														<label
															style={{
																fontWeight: "100",
																color: "#161c2d",
															}}
														>
															Tweets
														</label>
													</div>
													<div
														className="col"
														style={{ width: "100%", padding: 0 }}
													>
														<input
															onChange={updateFilter}
															type="text"
															className="form-control filter-input"
															id="minFollowersFilter"
															placeholder="min"
															data-type="tweets"
															data-change="min"
														/>
													</div>
													<div
														className="col"
														style={{ width: "100%", padding: 0 }}
													>
														<input
															onChange={updateFilter}
															type="text"
															className="form-control filter-input"
															id="maxFollowersFilter"
															placeholder="max"
															data-type="tweets"
															data-change="max"
														/>
													</div>
												</div>
											)}
											{followerFilter && (
												<div className="row filter-row">
													<div className="col">
														<label style={{ fontWeight: "500" }}>
															Followers
														</label>
													</div>
													<div className="col">
														<input
															onChange={updateFilter}
															type="text"
															className="form-control filter-input"
															id="minFollowersFilter"
															placeholder="min"
															data-type="followers"
															data-change="min"
														/>
													</div>
													<div className="col">
														<input
															onChange={updateFilter}
															type="text"
															className="form-control filter-input"
															id="maxFollowersFilter"
															placeholder="max"
															data-type="followers"
															data-change="max"
														/>
													</div>
												</div>
											)}
											{bioFilter && (
												<div className="row filter-row">
													<div className="col">
														<label style={{ fontWeight: "500" }}>
															Keywords
														</label>
													</div>
													<div className="col">
														<input
															onChange={updateFilter}
															type="text"
															className="form-control filter-input"
															id="minFollowersFilter"
															placeholder="e.g coach,course,fitness"
															style={{
																width: "23rem",
															}}
															data-type="keywords"
															data-change="keywords"
														/>
													</div>
												</div>
											)}
										</div>
									</div>

									<div
										style={{
											display: "float",
											marginLeft: "-5rem",
											marginTop: "2vh",
											zIndex: "999",
											position: "absolute",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												gap: "2rem",
											}}
										>
											<button
												className="find-action-button"
												onClick={addFind}
												type="button"
											>
												+
											</button>
											<button
												className="find-action-button"
												onClick={removeFind}
												type="button"
											>
												-
											</button>
										</div>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "3rem",
										}}
									>
										{findArray.map((find, idx) => {
											return (
												<div
													style={{
														backgroundColor: "#F7F7F7",
														padding: "1.5rem",
														borderRadius: "1rem",
														marginTop: "-2vh",
														border: "1px solid #E4E4E4",
													}}
												>
													<div className="form-group">
														<label
															style={{
																fontWeight: "100",
																color: "#161c2d",
															}}
															htmlFor="queryId"
														>
															{find.queryWord}
														</label>
														<input
															style={{
																height: "3.5rem",
																fontSize: "18.5px",
															}}
															onChange={(e) => changeFindQuery(e)}
															data-idx={idx}
															type="text"
															className="form-control"
															id="queryId"
															placeholder={find.queryPlaceholder}
														/>
													</div>
													<div
														id="input-div-cont"
														style={{ display: "block" }}
														className=" mb-3"
													>
														<div className="input-group-prepend"></div>
														<label
															style={{
																fontWeight: "100",
																color: "#161c2d",
															}}
															className="find-leads-label"
														>
															Find leads by:
														</label>
														<select
															onChange={(e) => changeFindType(e)}
															data-idx={idx}
															className="form-select form-select-sm"
															id="inputGroupSelect01"
															style={{
																height: "3.5rem",
																fontSize: "18.5px",
																marginTop: "0.2rem",
																color: "#5e5e5e",
															}}
														>
															<option value={"search"}>Search</option>
															<option value={"following"}>
																Following
															</option>
															<option value={"followers"}>
																Followers
															</option>
															<option value={"like"}>Like</option>
															<option value={"retweet"}>
																Retweet
															</option>
														</select>
													</div>
												</div>
											);
										})}
									</div>
									<div
										className="buttons-new-leads"
										style={{
											marginTop: "3.5rem",
											display: "flex",
											justifyContent: "space-evenly",
										}}
									>
										<div style={{ width: "50%" }}>
											<a
												onClick={() => navigate(-1)}
												style={{ textDecoration: "none" }}
											>
												<input
													style={{
														backgroundColor: "transparent",
														border: "none",
														color: "#007bff",
														fontFamily: "Poppins",
														fontSize: "17px",
														width: "50%",
													}}
													className="btn btn-primary form-btn"
													value="Cancel"
												></input>
											</a>
										</div>
										<div style={{ width: "50%" }}>
											<a style={{ textDecoration: "none", width: "100%" }}>
												<input
													style={{
														fontFamily: "Poppins",
														fontSize: "17px",
														width: "100%",
													}}
													className="btn btn-primary form-btn"
													type="submit"
													value="Continue"
												></input>
											</a>
										</div>
									</div>
								</div>
							</form>
						</section>
					</div>
				</div>
			</>
		);
	}
}
