import React from "react";
import "react-tooltip/dist/react-tooltip.css";

export default function Header() {
	return (
		<>
			<header>
				<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
				<link
					rel="stylesheet"
					href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
				></link>
			</header>

			{/*
			<div id="header-nav">
				<div className="hamburger-menu">
					<input id="menu__toggle" type="checkbox" />
					<label className="menu__btn" htmlFor="menu__toggle">
						<span></span>
					</label>

					<ul className="menu__box">
						<li>
							<a
								style={{ fontFamily: "Poppins" }}
								className="menu__item"
								href="/dashboard"
							>
								Dashboard
							</a>
						</li>
						<li style={{ marginTop: "2rem", marginBottom: "2rem" }}>
							<a
								style={{ fontFamily: "Poppins" }}
								className="menu__item"
								href="/campaigns/my"
							>
								My Campaigns
							</a>
						</li>
						<li>
							<a
								style={{ fontFamily: "Poppins" }}
								className="menu__item"
								href="/leads/my"
							>
								My Leads
							</a>
						</li>
					</ul>
				</div>
				<div>
					<p className="cold-parrot">ColdParrot</p>
				</div>
			</div>
			*/}

			<div id="nav-bar" className="navbar-body nav-bar w3-sidebar ">
				<div className="side-bar-svg">
					<a
						style={{ marginBottom: "2rem", marginTop: "2rem" }}
						className="hover-sidebar-icon"
						href="/dashboard"
					>
						<svg
							className="sidebar-icon"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.0799 21.0002 6.2 21.0002H8.2C8.48003 21.0002 8.62004 21.0002 8.727 20.9457C8.82108 20.8978 8.89757 20.8213 8.9455 20.7272C9 20.6203 9 20.4803 9 20.2002V13.6002C9 13.0402 9 12.7601 9.10899 12.5462C9.20487 12.3581 9.35785 12.2051 9.54601 12.1092C9.75992 12.0002 10.0399 12.0002 10.6 12.0002H13.4C13.9601 12.0002 14.2401 12.0002 14.454 12.1092C14.6422 12.2051 14.7951 12.3581 14.891 12.5462C15 12.7601 15 13.0402 15 13.6002V20.2002C15 20.4803 15 20.6203 15.0545 20.7272C15.1024 20.8213 15.1789 20.8978 15.273 20.9457C15.38 21.0002 15.52 21.0002 15.8 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424Z"
								stroke="#464646"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</a>

					<a
						style={{ marginBottom: "2rem", marginTop: "2rem" }}
						className="hover-sidebar-icon"
						href="/leads/my"
					>
						<svg
							className="sidebar-icon"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
								stroke="#464646"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</a>

					<a
						style={{ marginBottom: "2rem", marginTop: "2rem" }}
						className="hover-sidebar-icon"
						href="/campaigns/my"
					>
						<svg
							className="sidebar-icon"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10.4995 13.5002L20.9995 3.00017M10.6271 13.8282L13.2552 20.5862C13.4867 21.1816 13.6025 21.4793 13.7693 21.5662C13.9139 21.6415 14.0862 21.6416 14.2308 21.5664C14.3977 21.4797 14.5139 21.1822 14.7461 20.5871L21.3364 3.69937C21.5461 3.16219 21.6509 2.8936 21.5935 2.72197C21.5437 2.57292 21.4268 2.45596 21.2777 2.40616C21.1061 2.34883 20.8375 2.45364 20.3003 2.66327L3.41258 9.25361C2.8175 9.48584 2.51997 9.60195 2.43326 9.76886C2.35809 9.91354 2.35819 10.0858 2.43353 10.2304C2.52043 10.3972 2.81811 10.513 3.41345 10.7445L10.1715 13.3726C10.2923 13.4196 10.3527 13.4431 10.4036 13.4794C10.4487 13.5115 10.4881 13.551 10.5203 13.5961C10.5566 13.647 10.5801 13.7074 10.6271 13.8282Z"
								stroke="#464646"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</a>
					<a
						style={{ marginBottom: "2rem", marginTop: "2rem" }}
						className="hover-sidebar-icon"
						href="/conversations"
					>
						<svg
							className="sidebar-icon"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.2C21 14.8802 21 15.7202 20.673 16.362C20.3854 16.9265 19.9265 17.3854 19.362 17.673C18.7202 18 17.8802 18 16.2 18H13.6837C13.0597 18 12.7477 18 12.4492 18.0613C12.1844 18.1156 11.9282 18.2055 11.6875 18.3285C11.4162 18.4671 11.1725 18.662 10.6852 19.0518L8.29976 20.9602C7.88367 21.2931 7.67563 21.4595 7.50054 21.4597C7.34827 21.4599 7.20422 21.3906 7.10923 21.2716C7 21.1348 7 20.8684 7 20.3355V18C6.07003 18 5.60504 18 5.22354 17.8978C4.18827 17.6204 3.37962 16.8117 3.10222 15.7765C3 15.395 3 14.93 3 14V7.8Z"
								stroke="#464646"
								strokeWidth="2.2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</a>
				</div>
			</div>
		</>
	);
}
