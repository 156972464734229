import React, { useState, useEffect } from "react";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

export default function Dashboard() {
	const [analytics, setAnalytics] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/dashboard`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			let json = await response.json();
			setAnalytics(json);
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<>
				<div className="dashboard-container-wrapper">
					<div className="profile-section">
						<div
							className="img-acc-name"
							style={{ display: "flex", gap: "5rem", alignItems: "center" }}
						>
							<div>
								{" "}
								<img
									style={{ borderRadius: "50%", height: "100px", width: "100px" }}
									src={analytics.user.profile_image_url}
								></img>
							</div>
							<div>
								<p
									style={{
										fontFamily: "Poppins",
										fontWeight: 600,
										fontSize: "22px",
									}}
								>
									{analytics.user.name}
								</p>
								<div>
									<p
										style={{
											fontFamily: "Poppins",
											opacity: "0.7",
											fontSize: "18px",
										}}
									>
										{analytics.user.username}
									</p>
								</div>
							</div>
						</div>

						<div
							className="followers-following"
							style={{ display: "flex", gap: "5rem" }}
						>
							<div style={{ display: "block", margin: "auto", width: "80%" }}>
								<p
									style={{
										fontFamily: "Poppins",
										fontWeight: 600,
										fontSize: "22px",
									}}
								>
									{analytics.user.public_metrics.followers_count}
								</p>
								<p style={{ fontFamily: "Poppins", fontSize: "18px" }}>Followers</p>
							</div>
							<div style={{ display: "block", margin: "auto", width: "80%" }}>
								<p
									style={{
										fontFamily: "Poppins",
										fontWeight: 600,
										fontSize: "22px",
									}}
								>
									{analytics.user.public_metrics.following_count}
								</p>

								<p style={{ fontFamily: "Poppins", fontSize: "18px" }}>Following</p>
							</div>
						</div>
					</div>
					<div className="dashboard-container">
						<div className="dashboard-left">
							<h1 className="title-text">Analytics (7 days)</h1>
							<ResponsiveContainer
								className="responsive-container-recharts"
								width="99.5%"
								height="82%"
							>
								<AreaChart
									data={analytics.analytics}
									margin={{ top: 60, right: 30, left: 30, bottom: 0 }}
								>
									<defs>
										<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
											<stop
												offset="5%"
												stopColor="#28d456"
												stopOpacity={0.9}
											/>
											<stop
												offset="95%"
												stopColor="#28d456"
												stopOpacity={0.1}
											/>
										</linearGradient>
										<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
											<stop
												offset="5%"
												stopColor="#0f97ff"
												stopOpacity={0.9}
											/>
											<stop
												offset="95%"
												stopColor="#0f97ff"
												stopOpacity={0.1}
											/>
										</linearGradient>
									</defs>
									<XAxis dataKey="date" />
									<YAxis type="number" domain={[0, 100]} />
									<CartesianGrid strokeDasharray="3 3" />
									<Tooltip />
									<Legend />
									<Area
										type="monotone"
										dataKey="replied"
										stroke="#28d456"
										fillOpacity={1}
										fill="url(#colorUv)"
									/>
									<Area
										type="monotone"
										dataKey="sent"
										stroke="#0f97ff"
										fillOpacity={1}
										fill="url(#colorPv)"
									/>
								</AreaChart>
							</ResponsiveContainer>
						</div>

						<div className="dashboard-right">
							<div id="today-wrap-id" className="today-wrap">
								<div className="date-today">
									<h1 className="title-text">Today</h1>
								</div>
								<div className="today-info">
									<div className="statistic-contacted">
										<svg
											width="2rem"
											height="2rem"
											viewBox="0 0 24 24"
											strokeWidth="2"
											strokeOpacity="0.5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M7.5 10.5H7.51M12 10.5H12.01M16.5 10.5H16.51M9.9 19.2L11.36 21.1467C11.5771 21.4362 11.6857 21.5809 11.8188 21.6327C11.9353 21.678 12.0647 21.678 12.1812 21.6327C12.3143 21.5809 12.4229 21.4362 12.64 21.1467L14.1 19.2C14.3931 18.8091 14.5397 18.6137 14.7185 18.4645C14.9569 18.2656 15.2383 18.1248 15.5405 18.0535C15.7671 18 16.0114 18 16.5 18C17.8978 18 18.5967 18 19.1481 17.7716C19.8831 17.4672 20.4672 16.8831 20.7716 16.1481C21 15.5967 21 14.8978 21 13.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V13.5C3 14.8978 3 15.5967 3.22836 16.1481C3.53284 16.8831 4.11687 17.4672 4.85195 17.7716C5.40326 18 6.10218 18 7.5 18C7.98858 18 8.23287 18 8.45951 18.0535C8.76169 18.1248 9.04312 18.2656 9.2815 18.4645C9.46028 18.6137 9.60685 18.8091 9.9 19.2ZM8 10.5C8 10.7761 7.77614 11 7.5 11C7.22386 11 7 10.7761 7 10.5C7 10.2239 7.22386 10 7.5 10C7.77614 10 8 10.2239 8 10.5ZM12.5 10.5C12.5 10.7761 12.2761 11 12 11C11.7239 11 11.5 10.7761 11.5 10.5C11.5 10.2239 11.7239 10 12 10C12.2761 10 12.5 10.2239 12.5 10.5ZM17 10.5C17 10.7761 16.7761 11 16.5 11C16.2239 11 16 10.7761 16 10.5C16 10.2239 16.2239 10 16.5 10C16.7761 10 17 10.2239 17 10.5Z"
												stroke="#000000"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<p
											style={{ marginTop: "0.2rem", fontSize: "3rem" }}
											className="statistic-numbers"
										>
											{analytics.today.sent}{" "}
										</p>
										<h2
											style={{
												fontWeight: 600,
												fontSize: "17px",
												margin: "0rem",
												marginRight: "0rem",
												fontFamily: "Poppins",
											}}
										>
											Messaged
										</h2>
									</div>

									<div className="statistic-contacted">
										<svg
											width="2rem"
											height="2rem"
											viewBox="0 0 24 24"
											strokeWidth="2"
											strokeOpacity="0.5"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M13.744 2.63346L21.272 7.52667C21.538 7.69957 21.671 7.78602 21.7674 7.90134C21.8527 8.00342 21.9167 8.12149 21.9558 8.24865C22 8.39229 22 8.55092 22 8.86818V16.1999C22 17.88 22 18.7201 21.673 19.3619C21.3854 19.9263 20.9265 20.3853 20.362 20.6729C19.7202 20.9999 18.8802 20.9999 17.2 20.9999H6.8C5.11984 20.9999 4.27976 20.9999 3.63803 20.6729C3.07354 20.3853 2.6146 19.9263 2.32698 19.3619C2 18.7201 2 17.88 2 16.1999V8.86818C2 8.55092 2 8.39229 2.04417 8.24865C2.08327 8.12149 2.14735 8.00342 2.23265 7.90134C2.32901 7.78602 2.46201 7.69957 2.72802 7.52667L10.256 2.63346M13.744 2.63346C13.1127 2.22315 12.7971 2.01799 12.457 1.93817C12.1564 1.86761 11.8436 1.86761 11.543 1.93817C11.2029 2.01799 10.8873 2.22315 10.256 2.63346M13.744 2.63346L19.9361 6.65837C20.624 7.10547 20.9679 7.32902 21.087 7.61252C21.1911 7.86027 21.1911 8.13949 21.087 8.38724C20.9679 8.67074 20.624 8.89429 19.9361 9.34139L13.744 13.3663C13.1127 13.7766 12.7971 13.9818 12.457 14.0616C12.1564 14.1321 11.8436 14.1321 11.543 14.0616C11.2029 13.9818 10.8873 13.7766 10.256 13.3663L4.06386 9.34139C3.37601 8.89429 3.03209 8.67074 2.91297 8.38724C2.80888 8.13949 2.80888 7.86027 2.91297 7.61252C3.03209 7.32902 3.37601 7.10547 4.06386 6.65837L10.256 2.63346M21.5 18.9999L14.8572 12.9999M9.14282 12.9999L2.5 18.9999"
												stroke="#000000"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										<p
											style={{ marginTop: "0.2rem", fontSize: "3rem" }}
											className="statistic-numbers"
										>
											{analytics.today.replied}
										</p>
										<h2
											style={{
												fontWeight: 600,
												fontSize: "17px",
												margin: "0rem",
												fontFamily: "Poppins",
											}}
										>
											Replied
										</h2>
									</div>
								</div>
							</div>

							<div className="latest-events">
								<h1 className="title-text">Latest Events</h1>
								{analytics.events.slice(0, 5).map((event) => {
									return (
										<div key={event._id} className="event-container">
											<p style={{ marginLeft: "1rem" }}>{event.text} </p>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
