import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Root() {
	const [authenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const navigateToDashboard = () => {
		navigate("/dashboard");
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/authenticated`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			let json = await response.json();
			setAuthenticated(json.status);
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<>
				{!authenticated && (
					<div className="hero-authenticate" style={{ height: "70vh" }}>
						<svg
							style={{ opacity: "0.7", marginRight: "3rem" }}
							width="70px"
							height="70px"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9 3.5V2M5.06066 5.06066L4 4M5.06066 13L4 14.0607M13 5.06066L14.0607 4M3.5 9H2M8.5 8.5L12.6111 21.2778L15.5 18.3889L19.1111 22L22 19.1111L18.3889 15.5L21.2778 12.6111L8.5 8.5Z"
								stroke="#000000"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>

						<a className="authenticate-button" href="/authenticate">
							Authenticate
						</a>
					</div>
				)}
				{authenticated && navigateToDashboard()}
			</>
		);
	}
}
