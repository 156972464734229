import Header from "./includes/Header";
import React from "react";
import Dashboard from "./includes/Dashboard";
import Root from "./includes/Root";
import NewLeads from "./includes/NewLeads";
import MyLeads from "./includes/MyLeads";
import MyCampaigns from "./includes/MyCampaigns";
import CreateCampaign from "./includes/CreateCampaign";
import FindLeads from "./includes/FindLeads";
import Authenticate from "./Authenticate";
import ViewCampaign from "./includes/ViewCampaign";
import ViewLeads from "./includes/ViewLeads";
import FormHeader from "./includes/FormHeader";
import AllPages from "./includes/AllPages";
import Convo from "./includes/Convo";

import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					exact
					path="/"
					element={
						<>
							<Root />
						</>
					}
				/>
				<Route
					exact
					path="/dashboard"
					element={
						<>
							<AllPages />
							<Header />
							<Dashboard />
						</>
					}
				/>
				<Route
					exact
					path="/leads/new"
					element={
						<>
							<AllPages />
							<FormHeader />
							<NewLeads />
						</>
					}
				/>
				<Route
					exact
					path="/leads/find"
					element={
						<>
							<AllPages />
							<FormHeader />
							<FindLeads />
						</>
					}
				/>
				<Route
					exact
					path="/leads/my"
					element={
						<>
							<AllPages />
							<Header />
							<MyLeads />
						</>
					}
				/>
				<Route
					exact
					path="/leads/:id"
					element={
						<>
							<AllPages />
							<Header />
							<ViewLeads />
						</>
					}
				/>
				<Route
					exact
					path="/campaign/new"
					element={
						<>
							<AllPages />
							<FormHeader />
							<CreateCampaign />
						</>
					}
				/>
				<Route
					exact
					path="/campaigns/my"
					element={
						<>
							<AllPages />
							<Header />
							<MyCampaigns />
						</>
					}
				/>
				<Route
					exact
					path="/campaign/:id"
					element={
						<>
							<AllPages />
							<Header />
							<ViewCampaign />
						</>
					}
				/>

				<Route
					exact
					path="/conversations"
					element={
						<>
							<AllPages />
							<Header />
							<Convo />
						</>
					}
				/>

				<Route exact path="/authenticate" element={<Authenticate />}></Route>
			</Routes>
		</BrowserRouter>
	);
}
