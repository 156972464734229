import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function NewLeads(res, req) {
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	let handleSubmit = async (e) => {
		e.preventDefault();
		if (name === "") {
			toast.error("Name cannot be empty");
			return;
		}
		await fetch(`${process.env.REACT_APP_SERVER_URL}/leads/new`, {
			method: "POST",
			body: JSON.stringify({
				leads_name: name,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		});
		toast.success("Created new lead list");
		setName("");
		navigate("/leads/find");
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/authenticated`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			setLoading(false);
		});
	}, []);

	if (!loading) {
		return (
			<>
				<div className="main-wrap-form-leads">
					<div id="hero-forms" style={{ marginTop: "0rem" }}>
						<section id="new-lead-list">
							<form
								id="create-campaign-form-id-forms"
								onSubmit={handleSubmit}
								method="POST"
							>
								<div className="page-forms">
									<div className="about-campaigns form-name-leads">
										<div className="header-about-forms">
											<div style={{ marginBottom: "2rem" }}>
												<h2
													style={{
														fontWeight: "600",
														fontSize: "30px",
														color: "#505050",
														fontWeight: 600,
														fontFamily: "Poppins",
													}}
													className="form-name"
												>
													Now let's create a lead list
												</h2>
												<p
													style={{
														fontSize: "16px",
														color: "#8492a6",
														fontWeight: "100",
														fontFamily: "Poppins",
													}}
												>
													You can name it bellow
												</p>
											</div>
										</div>
									</div>

									<div className="form-group">
										<label
											style={{ fontWeight: "100", color: "#161c2d" }}
											htmlFor="exampleInputEmail1"
										>
											Name
										</label>
										<input
											style={{ height: "5rem", fontSize: "25px" }}
											value={name}
											type="text"
											className="form-control"
											id="leadsNameInput"
											aria-describedby="emailHelp"
											placeholder="e.g Fitness Coaches"
											onChange={(e) => setName(e.target.value)}
										/>
									</div>

									<br />
									<div
										className="buttons-new-leads"
										style={{ marginTop: "3.5rem" }}
									>
										<a
											onClick={() => navigate(-1)}
											style={{ textDecoration: "none" }}
										>
											<input
												style={{
													backgroundColor: "transparent",
													border: "none",
													color: "#007bff",
													fontFamily: "Poppins",
													fontSize: "17px",
													width: "100%",
												}}
												className="btn btn-primary form-btn"
												value="Cancel"
											></input>
										</a>
										<a style={{ width: "40%", textDecoration: "none" }}>
											<input
												style={{
													fontFamily: "Poppins",
													fontSize: "17px",
													width: "100%",
												}}
												className="btn btn-primary form-btn"
												type="submit"
												value="Continue"
											></input>
										</a>
									</div>
								</div>
							</form>
						</section>
					</div>
				</div>
			</>
		);
	}
}
