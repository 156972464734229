import React, { useState, useEffect, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { useNavigate } from "react-router-dom";

export default function Convo() {
	const [conversations, setConversations] = useState();
	const [hasConversations, setHasConversations] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [selected, setSelected] = useState({ conversation: [] });
	const [twitterId, setTwitterId] = useState();
	const [currentMessage, setCurrentMessage] = useState("");
	const messagesBottom = useRef(null);
	const [openedOption, setOpenedOption] = useState(-1);

	const { sendJsonMessage } = useWebSocket(
		`${process.env.REACT_APP_SERVER_URL.replace("http", "ws")}`,
		{
			onMessage: (message) => {
				let event = JSON.parse(message.data);
				let tempConversations = [...conversations];
				let conversationIdx = 0;
				for (let conversation of tempConversations) {
					if (conversation.id === event.sender) {
						tempConversations[conversationIdx].conversation.push(event);
						conversationIdx++;
					}
				}
				setConversations(tempConversations);
			},
		}
	);

	function changeConversationStatus(id, value) {
		fetch(`${process.env.REACT_APP_SERVER_URL}/conversations/update/status`, {
			method: "POST",
			body: JSON.stringify({
				id,
				status: value,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");

			let tempConversations = [...conversations];
			let conversationIdx = 0;
			for (let conversation of tempConversations) {
				if (conversation.id === id) {
					tempConversations[conversationIdx].status = value;
				}
				conversationIdx++;
			}
			setConversations(tempConversations);

			setOpenedOption(-1);
		});
	}

	function openOptions(idx) {
		if (idx === openedOption) {
			setOpenedOption(-1);
			return;
		}
		setOpenedOption(idx);
	}

	function handleSendMessageClick(e) {
		e.preventDefault();
		if (currentMessage === "") return;
		let tempSelected = { ...selected };
		let message = { text: currentMessage, to: selected.id, sender: twitterId };
		tempSelected.conversation.push(message);
		sendJsonMessage(message);
		setSelected(tempSelected);
		setCurrentMessage("");
	}

	useEffect(() => {
		messagesBottom.current?.scrollIntoView(false);
	}, [selected]);

	function changeSelected(index) {
		setSelected(conversations[index]);
	}

	useEffect(() => {
		fetch(`${process.env.REACT_APP_SERVER_URL}/conversations`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}).then(async function (response) {
			if (response.status === 401) return navigate("/");
			let json = await response.json();
			setConversations(json.conversations);
			setTwitterId(json.twitterId);
			if (json.conversations.length > 0) {
				setHasConversations(true);
				setSelected(json.conversations[0]);
			}
			setLoading(false);
		});
	}, []);

	if (!loading && hasConversations) {
		return (
			<div className="crm-main-wrapper">
				<div className="new-msg-crm">
					<div className="new-msg-boxes">
						{conversations.map((convo, idx) => {
							let backgroundColor = "white";
							let displayOptions = "none";
							let statusBackgroundColor = "gray";
							if (convo.screen_name === selected.screen_name)
								backgroundColor = "#f2f2f2";
							if (idx === openedOption) displayOptions = "flex";
							if (convo.status === "Not Interested") statusBackgroundColor = "red";
							if (convo.status === "Interested") statusBackgroundColor = "#0099ff";
							if (convo.status === "Meeting Booked") statusBackgroundColor = "purple";
							if (convo.status === "Closed") statusBackgroundColor = "green";
							return (
								<div
									className="new-msg-box"
									onClick={(e) => changeSelected(idx)}
									key={idx}
									style={{
										backgroundColor: backgroundColor,
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "row",
										}}
									>
										<img
											alt="Profile"
											className="convo-sidepanel"
											src={convo.profile_image_url}
											style={{
												display: "inline-block",
											}}
										></img>
										<div>
											<div>
												<p className="convo-sidepanel-name">{convo.name}</p>
												<span className="convo-sidepanel-message">
													{
														convo.conversation[
															convo.conversation.length - 1
														].text
													}
												</span>
											</div>
											<div>
												<span
													className="pill"
													style={{
														backgroundColor: statusBackgroundColor,
														fontFamily: "Poppins",
														fontWeight: "400",
														padding: "0.5rem",
														paddingTop: "0rem",
														paddingBottom: "0rem",
													}}
												>
													{convo.status || "Replied"}
												</span>
											</div>
										</div>
									</div>
									<div style={{ float: "right", margin: 0, padding: 0 }}>
										<button
											style={{
												border: "none",
												backgroundColor: "transparent",
											}}
											onClick={(e) => openOptions(idx)}
										>
											<svg
												width="1.5rem"
												height="1.5rem"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
													stroke="#000000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
													stroke="#000000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
													stroke="#000000"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
										<div
											className="crm-popup"
											style={{ display: displayOptions, float: "right" }}
										>
											<button
												type="button"
												className="crm-popup-button outline-not"
												onClick={(e) =>
													changeConversationStatus(
														convo.id,
														e.target.innerHTML
													)
												}
											>
												Not Interested
											</button>
											<button
												type="button"
												className="crm-popup-button outline-interested"
												onClick={(e) =>
													changeConversationStatus(
														convo.id,
														e.target.innerHTML
													)
												}
											>
												Interested
											</button>
											<button
												type="button"
												className="crm-popup-button outline-booked"
												onClick={(e) =>
													changeConversationStatus(
														convo.id,
														e.target.innerHTML
													)
												}
											>
												Meeting Booked
											</button>
											<button
												type="button"
												className="crm-popup-button outline-closed"
												onClick={(e) =>
													changeConversationStatus(
														convo.id,
														e.target.innerHTML
													)
												}
											>
												Closed
											</button>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div
					className="chat-box-crm"
					style={{
						display: "flex",
						flexDirection: "column",
						overflow: "inherit",
						justifyContent: "space-between",
					}}
				>
					{/*   <div className="chat-profile-crm">
                                        <img style={{ height: "50px", width: "50px", borderRadius: "50%", marginLeft: "0.5rem" }} src={imgas}></img>
                                        <div>
                                            <p style={{ fontWeight: 900, fontFamily: "Poppins" }}>T0dd</p>
                                            <p style={{ fontWeight: 900, fontFamily: "Poppins", opacity: 0.5, fontSize: "12px" }}>@todas</p>
                                        </div>
                                    </div>*/}
					<div
						className="chat-texting-crm"
						id="lastMessage"
						style={{ overflowY: "scroll" }}
					>
						<div className="more-about-profile">
							{"profile_image_url" in selected && (
								<img
									style={{
										height: "100px",
										width: "100px",
										borderRadius: "50%",
										marginBottom: "2rem",
									}}
									alt="Profile"
									src={selected.profile_image_url}
								></img>
							)}
							<p
								style={{
									fontWeight: 900,
									fontFamily: "Poppins",
									fontSize: "22px",
								}}
							>
								{selected.name}
							</p>
							<p
								style={{
									fontWeight: 900,
									fontFamily: "Poppins",
									opacity: 0.5,
									fontSize: "17px",
								}}
							>
								@{selected.screen_name}
							</p>
						</div>
						<div>
							{selected.conversation.map((message, idx) => {
								if (message.sender === twitterId)
									return (
										<div className="my-chats" key={idx}>
											<p
												style={{ fontSize: "18.5px" }}
												className="twitter-dm-text twitter-dm-text-my"
											>
												{message.text}
											</p>
										</div>
									);
								else
									return (
										<div className="other-chats" key={idx}>
											<p
												style={{ fontSize: "18.5px" }}
												className="twitter-dm-text twitter-dm-text-other"
											>
												{message.text}
											</p>
										</div>
									);
							})}
							<div ref={messagesBottom} />
						</div>
					</div>
					<form
						onSubmit={(e) => handleSendMessageClick(e)}
						className="form-reset"
						style={{
							height: "2rem",
						}}
					>
						<div className="behind-chat">
							<div className="chat-send-crm">
								<input
									style={{
										border: "none",
										backgroundColor: "#fafafa",
									}}
									onChange={(e) => setCurrentMessage(e.target.value)}
									type="text"
									className="form-control form-control-crm"
									value={currentMessage}
									placeholder="Start a new message"
								/>
								<button
									type="submit"
									style={{
										border: "none",
										backgroundColor: "rgb(250, 250, 250)",
									}}
								>
									<svg
										width="25px"
										height="25px"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.5004 11.9998H5.00043M4.91577 12.2913L2.58085 19.266C2.39742 19.8139 2.3057 20.0879 2.37152 20.2566C2.42868 20.4031 2.55144 20.5142 2.70292 20.5565C2.87736 20.6052 3.14083 20.4866 3.66776 20.2495L20.3792 12.7293C20.8936 12.4979 21.1507 12.3822 21.2302 12.2214C21.2993 12.0817 21.2993 11.9179 21.2302 11.7782C21.1507 11.6174 20.8936 11.5017 20.3792 11.2703L3.66193 3.74751C3.13659 3.51111 2.87392 3.39291 2.69966 3.4414C2.54832 3.48351 2.42556 3.59429 2.36821 3.74054C2.30216 3.90893 2.3929 4.18231 2.57437 4.72906L4.91642 11.7853C4.94759 11.8792 4.96317 11.9262 4.96933 11.9742C4.97479 12.0168 4.97473 12.0599 4.96916 12.1025C4.96289 12.1506 4.94718 12.1975 4.91577 12.2913Z"
											stroke="#464646"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
